import type { ReactNode } from "react";

export type BigButtonState = "enabled" | "disabled" | "loading";

export const BigButton = ({
  onClick,
  state = "enabled",
  type,
  children,
}: {
  onClick?: () => void;
  state?: BigButtonState;
  type?: "light" | "dark";
  children: ReactNode;
}) => {
  const buttonType =
    type === "light"
      ? "bg-white text-[#3C5043] border-1 border-[#3C5043]"
      : "bg-[#3C5043] text-white";
  return (
    <button
      onClick={() => {
        state === "enabled" && onClick && onClick();
      }}
      className={`relative flex h-12 w-full flex-row items-center justify-center  text-xl font-bold ${buttonType}  ${
        state !== "disabled"
          ? "cursor-pointer opacity-100 shadow-sm hover:opacity-80"
          : "opacity-10"
      } transition-colors duration-300`}
    >
      <div className="flex-1">{children}</div>
    </button>
  );
};
